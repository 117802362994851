import * as bootstrap from 'bootstrap';
import Inputmask from 'inputmask';
import TomSelect from 'tom-select';

import './dashboard.js'
import './datatable.js'

document.addEventListener("turbo:load", function () {
  // flash.forEach(function(message) {
  //   let notification = $.growl
  //
  //   switch(message[0])
  //   {
  //     case 'alert':
  //       notification = $.growl['warning']
  //     break
  //   }
  //
  //   notification({
  //     title: 'Info',
  //     message: message[1],
  //     location: 'br'
  //   })
  // })

  // Tooltips
  tooltipThem()

  // Masks
  maskThem()

  // Select
  select2Them()

  $('.datepicker').datepicker({
    format: 'dd/mm/yyyy',
    autoclose: true,
    todayHighlight: true,
    language: 'it',
    daysOfWeekHighlighted: "0,6",
    weekStart: 1
  })

  // $('.colorpicker').minicolors({
  //   control:  'hue',
  //   position: 'bottom',
  // });

  $('.datepicker[data-callback="appointments"]').on('changeDate', function(e) {
    let date = e.format()
    let url = this.dataset.url + `?start=${date}&end=${date}`
    $('#appointment_day').val(date)

    Turbo.fetch(url, {
      accept: 'application/json'
    }).then(response => response.json())
      .then(data => {
        const appointments = data.map(appointmentTemplate)
        const info = document.getElementById('appointments-info')
        
        document.querySelectorAll('.appointment-date').forEach(el => {
          el.textContent = date
        })
        document.getElementById('appointments-list').innerHTML = appointments.join('')
        
        if (appointments.length === 0) {
          info.classList.remove('d-none')
        } else {
          info.classList.add('d-none')
        }
      })
  })

  $('.with_appointment').on('change', function() {
    let appointment = $('#appointments-form')
    let list = $("#appointments-wrapper")
    
    if ( $(this).is(':checked') ) {
      appointment.removeClass('d-none')
      list.removeClass('d-none')
    } else {
      appointment.addClass('d-none');
      list.addClass('d-none')
    }
  })

  // $('.modal-ajax').on('show.bs.modal', function (event) {
  //   event.target.dataset.urlValue = $(event.relatedTarget).data('url')
  // })

  $('#modal-school').on('show.bs.modal', function (event) {
    event.target.dataset.schoolUpdateValue = $(event.relatedTarget).data('update')
  })

  $('body').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
    maskThem()
    select2Them()
  });

  clipboard()
  openModal()
})


function appointmentTemplate(appointment) {
  return `<div class="border-bottom p-2 ${appointment.class}">
    <div class="d-flex justify-content-between">
      <div class="align-items-center">
        <div class="text-muted">${appointment.start_at} - ${appointment.end_at}</div>
        <div>
          <i class="fa ${appointment.kind_icon} me-1"></i>
          <span>${appointment.description}</span>
        </div>
      </div>
      <div class="align-items-center d-flex ms-4">
        <span class="badge badge-indigo">
          <i class="fa fa-user me-1"></i>
          ${appointment.user_name}
        </span>
      </div>
    </div>
  </div>`
}

function clipboard() {
  let tooltipTriggerList = [].slice.call(document.querySelectorAll('.in-clipboard'))

  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl, {
      trigger: 'click',
      placement: 'bottom',
      title: 'Testo Copiato!'
    })
  })
  // $('body').tooltip({
  //   selector: '.in-clipboard',
  //   trigger: 'click',
  //   placement: 'bottom',
  //   title: 'Testo Copiato!'
  // });

  $(document).on('click', '.in-clipboard', function(){
    window.getSelection().selectAllChildren(this)
    document.execCommand('copy')
    let tooltip = bootstrap.Tooltip.getInstance($(this))
    tooltip.show()
    setTimeout(function(){
      tooltip.hide()
    }, 1500)
  })
}

function openModal() {
  if ( auto_open_modal != '' ) {
    // let modal = new bootstrap.Modal('#' + auto_open_modal)

    // if ( modal != undefined ) {
    //   modal.show()
    // }
  }
}

window.maskThem = function() {
  Inputmask({ alias: 'datetime', inputFormat: 'dd/mm/yyyy', placeholder: '_' }).mask(document.querySelectorAll('.mask-date'))
  Inputmask({ alias: 'email' }).mask(document.querySelectorAll('.mask-email'))
  Inputmask({ alias: 'currency', radixPoint: ',' }).mask(document.querySelectorAll('.mask-currency'))
}

window.select2Them = function() {
  // document.querySelectorAll('select.select2-select').forEach((el)=>{
  //   let settings = {
  //     plugins: ['change_listener', 'clear_button']
  //   };
  //   if ( el.multiple ) {
  //     settings.plugins.push('remove_button')
  //   }
  //   if ( el.tomselect === undefined) {
  //     new TomSelect(el, settings);
  //   }
  // });

  document.querySelectorAll('select.select2-ajax').forEach((el)=>{
    let settings = {
      plugins: ['change_listener', 'clear_button'],
      labelField: 'text',
      valueField: 'id',
      load: function(query, callback) {
        let url = el.dataset.searchUrl + '?q=' + encodeURIComponent(query);

        fetch(url)
          .then(response => response.json())
          .then(json => {
            callback(json.results);
          }).catch(()=>{
          callback();
        });

      },
    };
    if ( el.multiple ) {
      settings.plugins.push('remove_button')
    }
    if ( el.tomselect === undefined) {
      new TomSelect(el, settings);
    }
  });
}

window.tooltipThem = function() {
  let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))

  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
}
