import { Controller } from "@hotwired/stimulus"
import TomSelect from 'tom-select'

export default class extends Controller {
  static values = {
    clear: Boolean,
    max: Number,
  }

  connect() {
    this.tom = new TomSelect(this.element, this.options);
  }

  get options() {
    let options = {
      plugins: [],
    }

    if (this.clearValue) {
      options.plugins.push("clear_button")
    }

    if (this.element.multiple) {
      if (this.maxValue) {
        options.maxItems = this.maxValue
      }
      options.plugins.push("remove_button")
    } else {
      options.plugins.push("dropdown_input")
    }

    options.maxOptions = this.maxOptionsValue

    return options
  }
}
